/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[downloads page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH CATEGORIES ***********/
const FETCH_CATEGORIES_REQUEST = '[downloads page] fetch categories requested';
export const FetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST, props<any>());

const FETCH_CATEGORIES_SUCCESS = '[downloads page] fetch categories successful';
export const FetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS, props<{ list: any[] }>());

const FETCH_CATEGORIES_FAILURE = '[downloads page] fetch categories failure';
export const FetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

/******** FETCH CLIENTS FILTER ***********/
const FETCH_CLIENTS_FILTER_REQUEST = '[downloads page] fetch clients filter  requested';
export const FetchClientsFilterRequest = createAction(FETCH_CLIENTS_FILTER_REQUEST, props<any>());

const FETCH_CLIENTS_FILTER_SUCCESS = '[downloads page] fetch clients filter successful';
export const FetchClientsFilterSuccess = createAction(FETCH_CLIENTS_FILTER_SUCCESS, props<{ list: any[] }>());

const FETCH_CLIENTS_FILTER_FAILURE = '[downloads page] fetch clients filter failure';
export const FetchClientsFilterFailure = createAction(FETCH_CLIENTS_FILTER_FAILURE);

/******** SET CATEGORY ITEMS ***********/
const SET_CATEGORY_ITEMS = '[downloads page] set category items';
export const SetCategoryItems = createAction(SET_CATEGORY_ITEMS, props<any>());

/******** CLEAR CATEGORY ITEMS ***********/
const CLEAR_CATEGORY_ITEMS = '[downloads page] clear category items';
export const ClearCategoryItems = createAction(CLEAR_CATEGORY_ITEMS);

/******** ADD NEW TAXONOMY CATEGORY ***********/
const ADD_NEW_TAXONOMY_CATEGORY_REQUEST = '[downloads page] add new taxonomy category request';
export const AddNewTaxonomyCategoryRequest = createAction(
  ADD_NEW_TAXONOMY_CATEGORY_REQUEST,
  props<any>()
);

const ADD_NEW_TAXONOMY_CATEGORY_SUCCESS = '[downloads page] add new taxonomy category success';
export const AddNewTaxonomyCategorySuccess = createAction(
  ADD_NEW_TAXONOMY_CATEGORY_SUCCESS,
  props<any>()
);

const ADD_NEW_TAXONOMY_CATEGORY_FAILURE = '[downloads page] add new taxonomy category failure';
export const AddNewTaxonomyCategoryFailure = createAction(
  ADD_NEW_TAXONOMY_CATEGORY_FAILURE,
  props<any>()
);

/******** UPDATE TAXONOMY CATEGORY ***********/
const UPDATE_TAXONOMY_CATEGORY_REQUEST = '[downloads page] update taxonomy category request';
export const UpdateTaxonomyCategoryRequest = createAction(
  UPDATE_TAXONOMY_CATEGORY_REQUEST,
  props<any>()
);

const UPDATE_TAXONOMY_CATEGORY_SUCCESS = '[downloads page] update taxonomy category success';
export const UpdateTaxonomyCategorySuccess = createAction(
  UPDATE_TAXONOMY_CATEGORY_SUCCESS,
  props<any>()
);

const UPDATE_TAXONOMY_CATEGORY_FAILURE = '[downloads page] update taxonomy category failure';
export const UpdateTaxonomyCategoryFailure = createAction(
  UPDATE_TAXONOMY_CATEGORY_FAILURE,
  props<any>()
);

/******** ADD NEW DOWNLOAD ITEM ***********/
const ADD_NEW_DOWNLOAD_ITEM_REQUEST = '[downloads page] add new download item request';
export const AddNewDownloadItemRequest = createAction(
  ADD_NEW_DOWNLOAD_ITEM_REQUEST,
  props<any>()
);

const ADD_NEW_DOWNLOAD_ITEM_SUCCESS = '[downloads page] add new download item success';
export const AddNewDownloadItemSuccess = createAction(
  ADD_NEW_DOWNLOAD_ITEM_SUCCESS,
  props<any>()
);

const ADD_NEW_DOWNLOAD_ITEM_FAILURE = '[downloads page] add new download item failure';
export const AddNewDownloadItemFailure = createAction(
  ADD_NEW_DOWNLOAD_ITEM_FAILURE,
  props<any>()
);

/******** UPDATE DOWNLOAD ITEM ***********/
const UPDATE_DOWNLOAD_ITEM_REQUEST = '[downloads page] update download item request';
export const UpdateDownloadItemRequest = createAction(
  UPDATE_DOWNLOAD_ITEM_REQUEST,
  props<any>()
);

const UPDATE_DOWNLOAD_ITEM_SUCCESS = '[downloads page] update download item success';
export const UpdateDownloadItemSuccess = createAction(
  UPDATE_DOWNLOAD_ITEM_SUCCESS,
  props<any>()
);

const UPDATE_DOWNLOAD_ITEM_FAILURE = '[downloads page] update download item failure';
export const UpdateDownloadItemFailure = createAction(
  UPDATE_DOWNLOAD_ITEM_FAILURE,
  props<any>()
);

/******** FETCH ROLES FILTER ***********/
const FETCH_ROLES_FILTER_REQUEST = '[downloads page] fetch roles filter  requested';
export const FetchRolesFilterRequest = createAction(FETCH_ROLES_FILTER_REQUEST, props<any>());

const FETCH_ROLES_FILTER_SUCCESS = '[downloads page] fetch roles filter successful';
export const FetchRolesFilterSuccess = createAction(FETCH_ROLES_FILTER_SUCCESS, props<{ list: any[] }>());

const FETCH_ROLES_FILTER_FAILURE = '[downloads page] fetch roles filter failure';
export const FetchRolesFilterFailure = createAction(FETCH_ROLES_FILTER_FAILURE);

/******** DOWNLOAD ITEM ***********/
const DOWNLOAD_ITEM_REQUEST = '[downloads page] download item  requested';
export const DownloadItemRequest = createAction(DOWNLOAD_ITEM_REQUEST, props<any>());

const DOWNLOAD_ITEM_SUCCESS = '[downloads page] download item successful';
export const DownloadItemSuccess = createAction(DOWNLOAD_ITEM_SUCCESS, props<any>());

const DOWNLOAD_ITEM_FAILURE = '[downloads page] download item failure';
export const DownloadItemFailure = createAction(DOWNLOAD_ITEM_FAILURE);

/******** DELETE TAXONOMY ITEM ***********/
const DELETE_TAXONOMY_ITEM_REQUEST = '[downloads page] delete taxonomy item requested';
export const DeleteTaxonomyItemRequest = createAction(DELETE_TAXONOMY_ITEM_REQUEST, props<any>());

const DELETE_TAXONOMY_ITEM_SUCCESS = '[downloads page] delete taxonomy item successful';
export const DeleteTaxonomyItemSuccess = createAction(DELETE_TAXONOMY_ITEM_SUCCESS, props<any>());

const DELETE_TAXONOMY_ITEM_FAILURE = '[downloads page] delete taxonomy item failure';
export const DeleteTaxonomyItemFailure = createAction(DELETE_TAXONOMY_ITEM_FAILURE, props<any>());

/******** FETCH TAXONOMY SUBCATEGORY FORM DATA ***********/
const FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_REQUEST = '[downloads page] fetch taxonomy subcategory form data requested';
export const FetchTaxonomySubcategoryFormDataRequest = createAction(FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_REQUEST, props<any>());

const FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_SUCCESS = '[downloads page] fetch taxonomy subcategory form data successful';
export const FetchTaxonomySubcategoryFormDataSuccess = createAction(FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_SUCCESS, props<any>());

const FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_FAILURE = '[downloads page] fetch taxonomy subcategory form data failure';
export const FetchTaxonomySubcategoryFormDataFailure = createAction(FETCH_TAXONOMY_SUBCATEGORY_FORM_DATA_FAILURE, props<any>());

/******** FETCH DOWNLOAD ITEM ***********/
const FETCH_NEW_DOWNLOAD_ITEM_REQUEST = '[downloads page] fetch download item request';
export const FetchDownloadItemRequest = createAction(
  FETCH_NEW_DOWNLOAD_ITEM_REQUEST,
  props<any>()
);

const FETCH_NEW_DOWNLOAD_ITEM_SUCCESS = '[downloads page] fetch download item success';
export const FetchDownloadItemSuccess = createAction(
  FETCH_NEW_DOWNLOAD_ITEM_SUCCESS,
  props<any>()
);

const FETCH_NEW_DOWNLOAD_ITEM_FAILURE = '[downloads page] fetch download item failure';
export const FetchDownloadItemFailure = createAction(
  FETCH_NEW_DOWNLOAD_ITEM_FAILURE,
  props<any>()
);

/******** FETCH DOWNLOAD ITEM  DETAILS***********/
const FETCH_DOWNLOAD_ITEM_DETAILS_REQUEST = '[downloads page] fetch download item details request';
export const FetchDownloadItemDetailsRequest = createAction(
  FETCH_DOWNLOAD_ITEM_DETAILS_REQUEST,
  props<any>()
);

const FETCH_DOWNLOAD_ITEM_DETAILS_SUCCESS = '[downloads page] fetch download item details success';
export const FetchDownloadItemDetailsSuccess = createAction(
  FETCH_DOWNLOAD_ITEM_DETAILS_SUCCESS,
  props<any>()
);

const FETCH_DOWNLOAD_ITEM_DETAILS_FAILURE = '[downloads page] fetch download item details failure';
export const FetchDownloadItemDetailsFailure = createAction(
  FETCH_DOWNLOAD_ITEM_DETAILS_FAILURE,
  props<any>()
);
