import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {DropdownOption} from '../../../../models/dropdown-option.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../state/app.state';
import {
  AddNewSchoolRequest,
  FetchClientFilterRequest,
  FetchCountyFilterRequest,
  FetchOrganizationTypeFilterRequest,
  FetchProductTierFilterRequest,
  FetchSchoolDistrictFilterRequest,
  FetchSchoolFilterRequest,
  FetchSchoolTypesFilterRequest,
  FetchStateFilterRequest,
  UpdateSchoolRequest
} from '../../core/shared.actions';
import {
  clientFilter,
  countyFilter,
  organizationTypeFilter,
  productTierFilter,
  profile,
  schoolDistrictFilter,
  schoolFilter,
  schoolTypesFilter,
  stateFilter,
} from '../../core/shared.selectors';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {Router} from "@angular/router";
import {roles} from "../../constants/common.constants";

@Component({
  selector: 'app-add-or-edit-school',
  templateUrl: './add-or-edit-school.component.html',
  styleUrls: ['./add-or-edit-school.component.css'],
})
export class AddOrEditSchoolComponent implements OnInit, OnDestroy {
  @Input() data;
  @ViewChild('thumbnailUploadRef') thumbnailUploadRef: ElementRef;
  schoolData: any;
  schoolProfileDetails: any;
  errorMessages = {};
  errorMessageMap = {
    name: {
      required: 'Required',
    },
    displayAs: {
      required: 'Required',
    },
    abbreviation: {
      required: 'Required',
    },
    clientType: {
      required: 'Required',
    },
    logo: {},
    logoCheckbox: {},
    state: {
      required: 'Required',
    },
    county: {
      required: 'Required',
    },
    school: {
      required: 'Required',
    },
    schoolDistrict: {
      required: 'Required',
    },
    organizationType: {
      required: 'Required',
    },
    schoolType: {
      required: 'Required',
    },
    client: {
      required: 'Required',
    },
    productTier: {
      required: 'Required',
    }
  };

  checkBoxOptions = [
    {
      key: 1,
      value: 'Display Logo on Website',
    },
    {
      key: 2,
      value: 'Display Logo on Reports',
    },
  ];
  countyOptions = {
    list: [],
    loading: false,
  };
  schoolDistrictOptions = {
    list: [],
    loading: false,
  };
  organizationTypeOptions = {
    list: [],
    loading: false,
  };
  schoolTypeOptions = {
    list: [],
    loading: false,
  };
  schoolFilterOptions = {
    list: [],
    loading: false,
  };
  productTierOptions = {
    list: [],
    loading: false,
  };
  stateFilterOptions = {list: [], loading: false};
  clientFilterOptions = {list: [], loading: false};
  thumbnailUrl: string = '';
  isActive: boolean = true;

  form = new FormGroup({
    name: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    displayAs: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    abbreviation: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    organizationType: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    client: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    schoolType: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    productTier: new FormControl({value: null, disabled: false}, [
      Validators.required,
    ]),
    searchClient: new FormControl({value: '', disabled: false}),
    searchOrganizationType: new FormControl({value: '', disabled: false}),
    searchSchoolType: new FormControl({value: '', disabled: false}),
    searchState: new FormControl({value: '', disabled: false}),
    searchProductTier: new FormControl({value: '', disabled: false}),
    logo: new FormControl({value: '', disabled: true}, []),
    logoCheckbox: new FormControl({value: null, disabled: false}, []),
    state: new FormControl({value: null, disabled: false}),
    county: new FormControl({value: null, disabled: true}),
    searchCounty: new FormControl({value: '', disabled: false}),
    schoolDistrict: new FormControl({value: null, disabled: true}),
    searchSchoolDistrict: new FormControl({value: '', disabled: false}),
    school: new FormControl({value: null, disabled: true}),
    searchSchool: new FormControl({value: '', disabled: false}),
  });
  acceptedThumbnailFileTypes = [
    'image/jpeg', // JPG / JPEG
    'image/png', // PNG
  ];
  originalSchoolDistrictOptionsList: DropdownOption[];
  originalCountyOptionsList: DropdownOption[];
  originalStateFilterOptionsList: DropdownOption[];
  originalOrganizationTypeOptionsList: DropdownOption[];
  originalSchoolTypeOptionsList: DropdownOption[];
  file: any;
  clientId: number;
  originalClientFilterOptionsList: DropdownOption[]
  originalProductTierOptionsList: DropdownOption[]
  role: any;
  onClientsProfilePage: boolean;
  protected readonly roles = roles;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.role = data?.role;
        if (data?.role === 'Admin') {
          this.clientId = -1;
          this.store.dispatch(FetchOrganizationTypeFilterRequest({}));
          this.store.dispatch(FetchSchoolTypesFilterRequest({}));
          this.store.dispatch(FetchStateFilterRequest({}));
          this.store.dispatch(FetchClientFilterRequest({}));
          this.store.dispatch(FetchProductTierFilterRequest({}));
        } else {
          this.clientId = data?.client_id
        }
      })

    this.onClientsProfilePage = !!router.url.includes('client-details')

    if (this.onClientsProfilePage || this.role !== 'Admin') {
      this.form.controls.client.removeValidators([Validators.required]);
    }

    this.store
      .select(stateFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalStateFilterOptionsList = data?.list;
        this.stateFilterOptions.list = data?.list;
        this.stateFilterOptions.loading = data?.loading;
      });

    this.store
      .select(organizationTypeFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalOrganizationTypeOptionsList = data?.list;
        this.organizationTypeOptions.list = data?.list;
        this.organizationTypeOptions.loading = data?.loading;
      });

    this.store
      .select(schoolTypesFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalSchoolTypeOptionsList = data?.list;
        this.schoolTypeOptions.list = data?.list;
        this.schoolTypeOptions.loading = data?.loading;
      });

    this.store
      .select(countyFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.countyOptions.list = data?.list;
        this.originalCountyOptionsList = data?.list;
        this.countyOptions.loading = data?.loading;
      });

    this.store
      .select(schoolDistrictFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.schoolDistrictOptions.list = data?.list;
        this.originalSchoolDistrictOptionsList = data?.list;
        this.schoolDistrictOptions.loading = data?.loading;
      });

    this.store
      .select(schoolFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalSchoolDistrictOptionsList = data?.list;
        this.schoolFilterOptions.list = data?.list;
        this.schoolFilterOptions.loading = data?.loading;
      });

    this.store
      .select(clientFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalClientFilterOptionsList = data?.list;
        this.clientFilterOptions.list = data?.list;
        this.clientFilterOptions.loading = data?.loading;
      });

    this.store
      .select(productTierFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalProductTierOptionsList = data?.list;
        this.productTierOptions.list = data?.list;
        this.productTierOptions.loading = data?.loading;
      });
  }

  ngOnInit() {
    this.form.controls.searchState.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.stateFilterOptions.list = this.originalStateFilterOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.stateFilterOptions.list = this.originalStateFilterOptionsList;
        }
      });

    this.form.controls.searchCounty.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.countyOptions.list = this.originalCountyOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.countyOptions.list = this.originalCountyOptionsList;
        }
      });

    this.form.controls.searchSchoolDistrict.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.schoolDistrictOptions.list =
            this.originalSchoolDistrictOptionsList.filter((item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            });
        } else {
          this.schoolDistrictOptions.list =
            this.originalSchoolDistrictOptionsList;
        }
      });

    this.form.controls.searchOrganizationType.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.organizationTypeOptions.list = this.originalOrganizationTypeOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.organizationTypeOptions.list = this.originalOrganizationTypeOptionsList;
        }
      });

    this.form.controls.searchSchool.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.schoolFilterOptions.list = this.originalSchoolDistrictOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.schoolFilterOptions.list = this.originalSchoolDistrictOptionsList;
        }
      });

    this.form.controls.searchSchoolType.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.schoolTypeOptions.list = this.originalSchoolTypeOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.schoolTypeOptions.list = this.originalSchoolTypeOptionsList;
        }
      });

    this.form.controls.searchClient.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.clientFilterOptions.list = this.originalClientFilterOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.clientFilterOptions.list = this.originalClientFilterOptionsList;
        }
      });

    this.form.controls.searchProductTier.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.productTierOptions.list = this.originalProductTierOptionsList.filter(
            (item) => {
              return item?.value?.toLowerCase().includes(data.toLowerCase());
            }
          );
        } else {
          this.productTierOptions.list = this.originalProductTierOptionsList;
        }
      });

    const tempData = JSON.parse(JSON.stringify(this.data));
    if (tempData?.schoolData) {
      this.schoolData = tempData?.schoolData;

      if (this.schoolData.state_code) {
        this.fetchCountyFilter(this.schoolData.state_code)
      }

      if (this.schoolData.state_county_code) {
        this.fetchSchoolDistrictFilter(this.schoolData.state_county_code)
      }

      if (this.schoolData.state_district_code) {
        this.fetchSchoolFilter(this.schoolData.state_district_code)
      }


      this.schoolData.logoCheckbox = [
        !!this.schoolData.display_logo_on_report_flag && 1,
        !!this.schoolData.display_logo_on_website_flag && 2,
      ].filter(Boolean);

      this.form.patchValue(
        {
          name: this.schoolData?.name,
          logoCheckbox: this.schoolData?.logoCheckbox,
          organizationType: this.schoolData?.client_type_id || this.schoolData?.organizational_unit_type_id,
          state: +this.schoolData?.state_code,
          county: +this.schoolData?.state_county_code,
          schoolDistrict: +this.schoolData?.state_district_code,
          abbreviation: this.schoolData?.abbrev,
          displayAs: this.schoolData?.label,
          school: this.schoolData?.state_school_code,
          client: this.schoolData?.client_id,
          schoolType: this.schoolData?.school_type_id,
          productTier: this.schoolData?.pricing_tier_id,
        },
        {emitEvent: false}
      );

      this.thumbnailUrl = this.schoolData.logo_url;
      this.isActive = !!this.schoolData.active_flag;

      this.cdRef.detectChanges();
    }

    if (tempData?.schoolProfileDetails) {
      this.schoolProfileDetails = tempData?.schoolProfileDetails;
      if (this.schoolProfileDetails?.state_code) {
        this.fetchCountyFilter(this.schoolProfileDetails?.state_code)
      }

      if (this.schoolProfileDetails.state_county_code) {
        this.fetchSchoolDistrictFilter(this.schoolProfileDetails?.state_county_code)
      }

      if (this.schoolProfileDetails.state_district_code) {
        this.fetchSchoolFilter(this.schoolProfileDetails?.state_district_code)
      }
      this.schoolProfileDetails.logoCheckbox = [
        !!this.schoolProfileDetails.displayLogoOnWebsite && 1,
        !!this.schoolProfileDetails.displayLogoOnReports && 2,
      ].filter(Boolean);

      this.form.patchValue(
        {
          name: this.schoolProfileDetails?.name,
          logoCheckbox: this.schoolProfileDetails?.logoCheckbox,
          organizationType: this.schoolProfileDetails?.client_type_id,
          state: +this.schoolProfileDetails?.state_code,
          county: +this.schoolProfileDetails?.state_county_code,
          schoolDistrict: +this.schoolProfileDetails?.state_district_code,
          abbreviation: this.schoolProfileDetails?.abbrev,
          displayAs: this.schoolProfileDetails?.label,
          school: this.schoolProfileDetails?.state_school_code,
          client: this.schoolProfileDetails?.client_id,
          schoolType: this.schoolProfileDetails?.school_type_id,
          productTier: this.schoolProfileDetails?.pricing_tier_id,
        },
        {emitEvent: false}
      );

      this.thumbnailUrl = this.schoolProfileDetails.logo_url;
      this.isActive = !!this.schoolProfileDetails.active_flag;

      this.cdRef.detectChanges();
    }

    this.form.controls.state.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.fetchCountyFilter(data);
          this.form.controls.county.reset({value: null, disabled: false});
          this.form.controls.schoolDistrict.reset({value: null, disabled: true});
          this.form.controls.school.reset({value: null, disabled: true});
        }
      });

    this.form.controls.county.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.fetchSchoolDistrictFilter(data)
          this.form.controls.schoolDistrict.reset({value: null, disabled: false});
          this.form.controls.school.reset({value: null, disabled: true});
        }
      });

    this.form.controls.schoolDistrict.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.fetchSchoolFilter(data)
        }
      });

    if (this.onClientsProfilePage) {
      this.form.controls.client.removeValidators([Validators.required]);
    }
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  save() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      name,
      logoCheckbox,
      organizationType,
      state,
      county,
      schoolDistrict,
      abbreviation,
      displayAs,
      school,
      client,
      schoolType,
      productTier
    } = this.form.getRawValue();

    const payload = {
      productTier,
      name,
      client_type_id: organizationType,
      state_code: state,
      state_county_code: county,
      state_district_code: schoolDistrict,
      abbrev: abbreviation,
      label: displayAs,
      state_school_code: school,
      logo_file: this.file,
      active_flag: Number(!!this.isActive),
      client_id: this.clientId,
      elementary_flag: 0,
      middleschool_flag: 0,
      highschool_flag: 0,
      desc: '',
      schoolType,
      ...(this.schoolData ? {school_id: this.schoolData?.organizational_unit_id} : {}),
      ...(this.schoolProfileDetails ? {school_id: this.schoolProfileDetails.organizational_unit_id} : {}),
      ...(this.schoolData || this.schoolProfileDetails ? {client_id: this.clientId} : {}),
      ...(this.role === 'Admin' ? {client_id: client} : {}),
      ...(this.role === 'Admin' && this.data?.clientIdFromClientProfilePage ? {client_id: this.data?.clientIdFromClientProfilePage} : {}),
    };
    if (logoCheckbox?.length) {
      payload['display_logo_on_website_flag'] = Number(!!logoCheckbox.includes(1));
      payload['display_logo_on_report_flag'] = Number(!!logoCheckbox.includes(2));
    }

    if (this.schoolData || this.schoolProfileDetails) {
      this.store.dispatch(UpdateSchoolRequest({payload}));
    } else {
      this.store.dispatch(AddNewSchoolRequest({payload}));
    }
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  handleThumbnailUpload() {
    this.file = this.thumbnailUploadRef.nativeElement.files[0];
    if (this.file) {
      if (!this.acceptedThumbnailFileTypes.includes(this.file?.type)) {
        this.commonService.notification('Invalid file type.', 'danger');
        return;
      }

      if (this.file.size > 5242880) {
        this.commonService.notification('File size larger than 5 mb', 'danger');
        return;
      }

      this.thumbnailUrl = URL.createObjectURL(this.file);
      this.form.controls.logo.setValue(this.file.name, {emitEvent: false});
    }

    this.thumbnailUploadRef.nativeElement.value = '';
    this.form.markAsDirty();
  }

  toggle(event: MatSlideToggleChange) {
    this.isActive = event.checked;
  }

  fetchCountyFilter(data) {
    const payload = {
      state_id: data
    }
    this.form.controls.county.enable();
    this.store.dispatch(FetchCountyFilterRequest({payload}));
  }

  fetchSchoolDistrictFilter(data) {
    const payload = {
      county_id: data
    }
    this.form.controls.schoolDistrict.enable();
    this.store.dispatch(FetchSchoolDistrictFilterRequest({payload}));
  }

  fetchSchoolFilter(data) {
    const payload = {
      district_id: data
    }
    this.form.controls.school.enable();
    this.store.dispatch(FetchSchoolFilterRequest({payload}));
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
