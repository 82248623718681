<div
  class="component-container w-full flex flex-col justify-center items-center"
>
  <div class="content w-full flex flex-col justify-center items-center">
    <form class="flex flex-col xs:px-0 md:px-[2rem] lg:px-[8rem] w-full">
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Name
        </div>
        <app-text-input
          [error]="errorMessages['name']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="name"
          placeholder="Enter School Name"
        ></app-text-input>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Display As
        </div>
        <app-text-input
          [error]="errorMessages['displayAs']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="displayAs"
          placeholder="Enter Display Name"
        ></app-text-input>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Organization Type
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['organizationType']"
          [loading]="organizationTypeOptions.loading"
          [options]="organizationTypeOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="organizationType"
          filterControlName="searchOrganizationType"
          placeholder="Select Organization Type"
        ></app-search-on-select>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        [class.is-active]="role === roles.clientAdmin"
        class="flex flex-row justify-start items-center gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 xs:w-1/6 flex sm:justify-end justify-start mb-[2rem]"
        >
          Active
        </div>
        <mat-slide-toggle
          (change)="toggle($event)"
          *ngIf="role !== roles.clientAdmin; else booleanString "
          [checked]="isActive"
          class="input toggle sm:w-2/3 xs:w-1/6"
        >
        </mat-slide-toggle>
        <ng-template
          #booleanString
        >
          {{ isActive ? 'Yes' : 'No' }}
        </ng-template>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-start items-start gap-[2rem]"
      >
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start">
          Logo
        </div>
        <div class="input sm:w-2/3 w-full flex flex-col">
          <div
            class="thumbnail-section mb-[1rem] xs:flex xs:justify-start xs:items-center"
          >
            <app-image
              [noImageForOrg]=true
              [url]="thumbnailUrl"
              class="w-[15rem] h-[15rem] block border"
            ></app-image>
          </div>
          <div class="thumbnail-action-section flex flex-row gap-[1rem]">
            <app-primary-button
              (handleClick)="openFile('thumbnailUploadRef')"
              class="w-4/12"
            >
              Browse
            </app-primary-button>
            <app-text-input
              (checkForErrors)="checkForErrors('logo')"
              [error]="errorMessages['logo']"
              [parentFormGroup]="form"
              class="w-8/12"
              controlName="logo"
              placeholder="Select an Image"
            ></app-text-input>
            <input
              #thumbnailUploadRef
              (change)="handleThumbnailUpload()"
              [id]="'thumbnailUploadRef'"
              class="input-files"
              type="file"
            />
          </div>
          <!--          DO NOT DELETE THE CODE NEEDED LATER -->
          <!--          <div class="logo-attributes">-->
          <!--            <app-checkbox-->
          <!--              [options]="checkBoxOptions"-->
          <!--              [parentFormGroup]="form"-->
          <!--              class="w-full"-->
          <!--              controlName="logoCheckbox"-->
          <!--            ></app-checkbox>-->
          <!--          </div>-->
        </div>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="sub-heading">State Designation
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          State
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['state']"
          [loading]="stateFilterOptions?.loading"
          [options]="stateFilterOptions?.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="state"
          filterControlName="searchState"
          placeholder="Select State"
        ></app-search-on-select>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          County
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['county']"
          [loading]="countyOptions.loading"
          [options]="countyOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="county"
          filterControlName="searchCounty"
          placeholder="Select a county"
        ></app-search-on-select>
      </div>
      <div
        *ngIf="role === roles.superAdmin"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          School District
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['schoolDistrict']"
          [loading]="schoolDistrictOptions.loading"
          [options]="schoolDistrictOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="schoolDistrict"
          filterControlName="searchSchoolDistrict"
          placeholder="Select a school District"
        ></app-search-on-select>
      </div>
    </form>
    <div
      [class.push-down]="role !== roles.superAdmin"
      class="action-section w-[80%] sm:w-1/3 flex flex-row justify-center items-center"
    >
      <app-primary-button (handleClick)="save()" class="w-full">
        Save
      </app-primary-button>
    </div>
  </div>
</div>
